<template>
  <section>
    <section
      v-if="noResult === true"
      class="noResult"
    >
      <img
        src="../../assets/icons/fail.svg"
        alt="fail"
      >
      <span>Sorry, No Result Found</span>
    </section>
    <section v-if="noResult === false">
      <section class="top">
        <div>
          <p class="tt_title">
            TimeTable
          </p>
          <p class="tt_subtitle">
            Class {{ tt.class }}
          </p>
        </div>
        <div class="top-btn">
          <VBtn
            icon
            large
            class="shadow ma-0"
            color="white"
            @click="confDel = true"
          >
            <VIcon>delete</VIcon>
          </VBtn>
          <VBtn
            round
            large
            class="shadow"
            color="white"
            @click="update()"
          >
            Update
          </VBtn>
        </div>
      </section>
      <section class="content">
        <div class="grid">
          <VCard class="shadow tt_card">
            <p class="tt_main_title">
              Monday
            </p>
            <p class="tt_main_subtitle">
              Class {{ tt.class }}
            </p>
            <div class="mt-3">
              <p class="tt_main_txt">
                {{ tt.day.monday.p1 }}
              </p>
              <p class="tt_main_subtitle">
                1st Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.monday.p2 }}
              </p>
              <p class="tt_main_subtitle">
                2nd Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.monday.p3 }}
              </p>
              <p class="tt_main_subtitle">
                3rd Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.monday.p4 }}
              </p>
              <p class="tt_main_subtitle">
                4th Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.monday.p5 }}
              </p>
              <p class="tt_main_subtitle">
                5th Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.monday.p6 }}
              </p>
              <p class="tt_main_subtitle">
                6th Period
              </p>
            </div>
            <div
              v-if="tt.day.monday.p7 != null"
              class="mt-2"
            >
              <p class="tt_main_txt">
                {{ tt.day.monday.p7 }}
              </p>
              <p class="tt_main_subtitle">
                7th Period
              </p>
            </div>
          </VCard>
          <VCard class="shadow tt_card">
            <p class="tt_main_title">
              Tuesday
            </p>
            <p class="tt_main_subtitle">
              Class {{ tt.class }}
            </p>
            <div class="mt-3">
              <p class="tt_main_txt">
                {{ tt.day.tuesday.p1 }}
              </p>
              <p class="tt_main_subtitle">
                1st Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.tuesday.p2 }}
              </p>
              <p class="tt_main_subtitle">
                2nd Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.tuesday.p3 }}
              </p>
              <p class="tt_main_subtitle">
                3rd Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.tuesday.p4 }}
              </p>
              <p class="tt_main_subtitle">
                4th Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.tuesday.p5 }}
              </p>
              <p class="tt_main_subtitle">
                5th Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.tuesday.p6 }}
              </p>
              <p class="tt_main_subtitle">
                6th Period
              </p>
            </div>
            <div
              v-if="tt.day.tuesday.p7 != null"
              class="mt-2"
            >
              <p class="tt_main_txt">
                {{ tt.day.tuesday.p7 }}
              </p>
              <p class="tt_main_subtitle">
                7th Period
              </p>
            </div>
          </VCard>
          <VCard class="shadow tt_card">
            <p class="tt_main_title">
              Wednesday
            </p>
            <p class="tt_main_subtitle">
              Class {{ tt.class }}
            </p>
            <div class="mt-3">
              <p class="tt_main_txt">
                {{ tt.day.wednesday.p1 }}
              </p>
              <p class="tt_main_subtitle">
                1st Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.wednesday.p2 }}
              </p>
              <p class="tt_main_subtitle">
                2nd Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.wednesday.p3 }}
              </p>
              <p class="tt_main_subtitle">
                3rd Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.wednesday.p4 }}
              </p>
              <p class="tt_main_subtitle">
                4th Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.wednesday.p5 }}
              </p>
              <p class="tt_main_subtitle">
                5th Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.wednesday.p6 }}
              </p>
              <p class="tt_main_subtitle">
                6th Period
              </p>
            </div>
            <div
              v-if="tt.day.wednesday.p7 != null"
              class="mt-2"
            >
              <p class="tt_main_txt">
                {{ tt.day.wednesday.p7 }}
              </p>
              <p class="tt_main_subtitle">
                7th Period
              </p>
            </div>
          </VCard>
          <VCard class="shadow tt_card">
            <p class="tt_main_title">
              Thursday
            </p>
            <p class="tt_main_subtitle">
              Class {{ tt.class }}
            </p>
            <div class="mt-3">
              <p class="tt_main_txt">
                {{ tt.day.thursday.p1 }}
              </p>
              <p class="tt_main_subtitle">
                1st Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.thursday.p2 }}
              </p>
              <p class="tt_main_subtitle">
                2nd Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.thursday.p3 }}
              </p>
              <p class="tt_main_subtitle">
                3rd Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.thursday.p4 }}
              </p>
              <p class="tt_main_subtitle">
                4th Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.thursday.p5 }}
              </p>
              <p class="tt_main_subtitle">
                5th Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.thursday.p6 }}
              </p>
              <p class="tt_main_subtitle">
                6th Period
              </p>
            </div>
            <div
              v-if="tt.day.thursday.p7 != null"
              class="mt-2"
            >
              <p class="tt_main_txt">
                {{ tt.day.thursday.p7 }}
              </p>
              <p class="tt_main_subtitle">
                7th Period
              </p>
            </div>
          </VCard>
          <VCard class="shadow tt_card">
            <p class="tt_main_title">
              Friday
            </p>
            <p class="tt_main_subtitle">
              Class {{ tt.class }}
            </p>
            <div class="mt-3">
              <p class="tt_main_txt">
                {{ tt.day.friday.p1 }}
              </p>
              <p class="tt_main_subtitle">
                1st Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.friday.p2 }}
              </p>
              <p class="tt_main_subtitle">
                2nd Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.friday.p3 }}
              </p>
              <p class="tt_main_subtitle">
                3rd Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.friday.p4 }}
              </p>
              <p class="tt_main_subtitle">
                4th Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.friday.p5 }}
              </p>
              <p class="tt_main_subtitle">
                5th Period
              </p>
            </div>
            <div class="mt-2">
              <p class="tt_main_txt">
                {{ tt.day.friday.p6 }}
              </p>
              <p class="tt_main_subtitle">
                6th Period
              </p>
            </div>
            <div
              v-if="tt.day.friday.p7 != null"
              class="mt-2"
            >
              <p class="tt_main_txt">
                {{ tt.day.friday.p7 }}
              </p>
              <p class="tt_main_subtitle">
                7th Period
              </p>
            </div>
          </VCard>
        </div>
      </section>
    </section>
    <VDialog
      v-model="confDel"
      persistent
      width="300"
    >
      <ConfirmDelete
        @close="confDel = false"
        @delete="connfDel()"
      />
    </VDialog>
    <VDialog
      v-model="isLoading"
      persistent
      fullscreen
      transition="dialog-bottom-transition"
    >
      <VCard
        v-if="loadStatus === 'loading'"
        class="notification_fs elevation-20"
      >
        <p>Loading</p>
        <span>Fetching Data, Please Wait...</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="white"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'netfail'"
        class="notification_fs elevation-20"
      >
        <p>Internet Issue</p>
        <span>Please Try Again Later</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="red"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'fail'"
        class="notification_fs elevation-20"
      >
        <p>Error</p>
        <span>An Error Occured</span>
        <div class="notification_ico">
          <VBtn
            depressed
            round
            color="primary"
            @click="reloadPage()"
          >
            Reload
          </VBtn>
        </div>
      </VCard>
    </VDialog>
    <VDialog
      v-model="isDeleting"
      width="300"
      persistent
    >
      <VCard
        v-if="loadStatus === 'deleting'"
        class="notification_card elevation-20"
      >
        <p>Deleting</p>
        <span>Deleting TimeTable, Please Wait...</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'netfail'"
        class="notification_card elevation-20"
      >
        <p>Internet Issue</p>
        <span>Retrying In {{ retry }} Seconds</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="red"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'fail'"
        class="notification_card elevation-20"
      >
        <p>Error</p>
        <span>An Error Occured</span>
        <div class="notification_ico">
          <VBtn
            depressed
            round
            color="primary"
            @click="reloadPage()"
          >
            Reload
          </VBtn>
        </div>
      </VCard>
    </VDialog>
  </section>
</template>

<script>
import axios from 'axios';
import api from '../../conf/api';
import ConfirmDelete from '../../components/confirmDelete.vue';

export default {
  components: {
    ConfirmDelete,
  },
  data: () => ({
    isLoading: true,
    isDeleting: false,
    loadStatus: 'loading',
    retry: 0,
    confDel: false,
    noResult: false,
    tt: {
      day: {
        monday: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: null,
        },
        tuesday: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: null,
        },
        wednesday: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: null,
        },
        thursday: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: null,
        },
        friday: {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: '',
          p7: null,
        },
      },
    },
  }),
  async beforeMount() {
    try {
      const { id } = this.$route.params;
      await axios.get(`${api}/timetable/${id}`)
        .then((res) => {
          if (res.data.status === 'success') {
            this.tt = res.data.data;
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.noResult = true;
          }
        });
    } catch (e) {
      if (e.code === 'auth/network-request-failed') {
        this.loadStatus = 'netfail';
        setTimeout(() => {
          this.isLoading = false;
          this.$router.push('/timetable');
        }, 5000);
      } else {
        this.loadStatus = 'fail';
      }
    }
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    update() {
      const { id } = this.$route.params;
      this.$router.push(`/timetable/update/${id}`);
    },
    connfDel() {
      this.isDeleting = true;
      this.loadStatus = 'deleting';
      const { id } = this.$route.params;
      axios.delete(`${api}/timetable/del/${id}`).then(() => {
        this.confDel = false;
        this.isDeleting = false;
        this.$router.push('/timetable');
      })
        .catch((e) => {
          if (e.code === 'auth/network-request-failed') {
            this.loadStatus = 'netfail';
            this.retry = 10;
            setInterval(() => {
              this.retry -= 1;
            }, 1000);
            setTimeout(() => {
              window.location.reload();
            }, 9500);
          } else {
            this.loadStatus = 'fail';
          }
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/TimeTable/view';
</style>
